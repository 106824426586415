import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  PROJECT_STATUS_COLOR_MAP,
  PROJECT_STATUS_TEXT_MAP,
} from '@poly/site-ui';
import {
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Button,
  Editor,
  Input,
  Modal,
  Grid,
  Icon,
  Pip,
  L,
  S,
} from '@poly/site-book';

import Select from '../select/index.js';
import ButtonLoader from '../loader/button.js';
import { Rows } from '../../util/forms/index.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import { projectPriorityField } from './fields/priority.js';
import { ProjectStatusesArray } from '../../constants/projects.js';
import { EmailInputWithSearchUser } from './EmailInputWithSearchUser.js';
import { prepareRequesterToOption } from './add-work-order.js';
import {
  formatSearchPlaceholder,
  formatPlaceholder,
} from '../../util/select.js';

const Form = Modal.Item.withComponent(Grid);

const MAX_WIDTH = 320;

const Fields = styled.section`
  display: grid;
  margin-top: -10px;
  grid-row-gap: 10px;
  grid-auto-rows: min-content;
  grid-template-rows: repeat(2, 1fr);
`;

const Link = S.withComponent(L);

function RequesterFields({ title, onClick, text }) {
  return (
    <Fields>
      <S textColor="#6f7a9f" size={12}>
        {title}
      </S>
      <Link {...{ onClick }} size={12} uppercase bold>
        {text}
      </Link>
    </Fields>
  );
}

RequesterFields.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

function SimpleContentTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleContentTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function SimpleTitle({ label }) {
  return <S type="title">{label}</S>;
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function ColoredTitle({ label }) {
  return (
    <Pip color={PROJECT_STATUS_COLOR_MAP[label]}>
      <S type="title">{PROJECT_STATUS_TEXT_MAP[label]}</S>
    </Pip>
  );
}

ColoredTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditProject({
  canSelectPriority,
  editProjectModal,
  activeProjectId,
  isNewRequester,
  isModalOpen: show,

  // handlers
  onSubmit,
  closeModal,
  onNewRequesterChange,
  onChange,
  setRef,
  toggleIsNewRequester,
  selectRequester,
  selectPriority,
  selectProperty,
  selectService,
  selectStatus,
  setPropertySearch,
  setSearchRequesters,

  // values
  selectedRequester,
  selectedPriority,
  selectedProperty,
  selectedStatus,
  selectedService,
  serviceTypes,
  newRequester,
  properties,
  requesters,
  loading,
  errors,
  propertyLoading,
  requestersLoading,
  serviceLoading,
  setServiceSearch,
  noPropertyResults,
  noRequesterResults,
  setNewRequester,
}) {
  const requestersOptions = R.map(prepareRequesterToOption, requesters);

  const setFoundUser = (userContact) => {
    const userOption = prepareRequesterToOption(userContact);
    selectRequester(userOption);
    toggleIsNewRequester();
    setNewRequester({});
  };
  return (
    <Modal {...{ show }}>
      <Modal.Item>
        <Heading>
          <HeadingH5>{activeProjectId}</HeadingH5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <HeadingH3 lighter>Edit Work Order</HeadingH3>
      </Modal.Item>
      <Modal.Item margin={20}>
        <HeadingH5>WO Information</HeadingH5>
      </Modal.Item>
      <form {...{ onSubmit }}>
        <Form
          columns={`120px minmax(200px, ${MAX_WIDTH}px)`}
          margin={30}
          simple
        >
          <Rows
            items={[
              ...(canSelectPriority
                ? [
                    projectPriorityField({
                      selectedPriority,
                      errors,
                      selectPriority,
                    }),
                  ]
                : []),
              {
                title: {
                  value: 'Status',
                },
                item: (
                  <Select
                    value={selectedStatus}
                    onChange={selectStatus}
                    placeholder={formatPlaceholder('Status')}
                    options={ProjectStatusesArray.map((status) => ({
                      value: status,
                      label: status,
                    }))}
                    optionRenderer={ColoredTitle}
                  />
                ),
              },
              {
                title: {
                  value: 'Type',
                  props: ['required'],
                },
                error: R.prop('service', errors),
                item: (
                  <Select
                    value={selectedService}
                    onChange={selectService}
                    onInputChange={setServiceSearch}
                    isLoading={serviceLoading}
                    placeholder={formatPlaceholder('Service Type')}
                    options={serviceTypes.map((serviceType) => ({
                      value: R.prop('_id', serviceType),
                      label: R.prop('name', serviceType),
                    }))}
                    optionRenderer={SimpleTitle}
                    invalid={!!R.prop('service', errors)}
                  />
                ),
              },
              {
                title: {
                  value: 'Description',
                  props: ['required'],
                },
                error: R.prop('description', errors),
                item: (
                  <Input
                    name="description"
                    placeholder="Description"
                    defaultValue={R.path(
                      ['data', 'description'],
                      editProjectModal,
                    )}
                    invalid={!!R.prop('description', errors)}
                    {...{ onChange }}
                  />
                ),
              },
              {
                title: {
                  value: 'Details',
                  props: ['', 'description'],
                },
                item: (
                  <Editor
                    registerRef={setRef}
                    placeholder="Description"
                    defaultValue={R.path(['data', 'details'], editProjectModal)}
                  />
                ),
              },
              {
                title: {
                  value: 'Location',
                },
                item: (
                  <Input
                    name="location"
                    placeholder="Enter a Location"
                    defaultValue={R.path(
                      ['data', 'location'],
                      editProjectModal,
                    )}
                  />
                ),
              },
              {
                title: {
                  value: 'Property',
                  props: ['required'],
                },
                error: R.prop('property', errors),
                item: (
                  <Select
                    value={selectedProperty}
                    onChange={selectProperty}
                    onInputChange={setPropertySearch}
                    isLoading={propertyLoading}
                    placeholder={formatSearchPlaceholder('Property')}
                    noResultsText={noPropertyResults}
                    options={properties.map((property) => ({
                      value: R.prop('_id', property),
                      label: R.prop('name', property),
                    }))}
                    invalid={!!R.prop('property', errors)}
                    optionRenderer={SimpleContentTitle}
                  />
                ),
              },
              {
                title: isNewRequester
                  ? {
                      value: 'New Requester',
                      props: ['required'],
                    }
                  : {
                      value: 'Requester',
                    },
                error: R.prop('requesterName', errors),
                item: isNewRequester ? (
                  <Input
                    name="requesterName"
                    placeholder="Enter Requester Name"
                    value={R.prop('name', newRequester)}
                    onChange={onNewRequesterChange('name')}
                    invalid={!!R.prop('requesterName', errors)}
                  />
                ) : (
                  <Select
                    onChange={selectRequester}
                    onInputChange={setSearchRequesters}
                    isLoading={requestersLoading}
                    value={selectedRequester}
                    placeholder={formatSearchPlaceholder('Requester')}
                    noResultsText={noRequesterResults}
                    options={requestersOptions}
                    optionRenderer={SimpleContentTitle}
                  />
                ),
              },
              {
                title: isNewRequester
                  ? {
                      value: 'Email',
                      props: ['required'],
                    }
                  : {
                      key: 'email',
                    },
                error: R.prop('requesterEmail', errors),
                item: isNewRequester ? (
                  <EmailInputWithSearchUser
                    name="requesterEmail"
                    placeholder="Enter Unique Requester Email"
                    value={R.prop('email', newRequester)}
                    onChange={onNewRequesterChange('email')}
                    invalid={!!R.prop('requesterEmail', errors)}
                    setFoundUser={setFoundUser}
                  />
                ) : (
                  <Input
                    name="requesterEmail"
                    placeholder="Enter Unique Requester Email"
                    value={R.prop('email', selectedRequester) || '—'}
                    disabled
                  />
                ),
              },
              {
                title: isNewRequester
                  ? {
                      value: 'Phone',
                      props: ['required'],
                    }
                  : {
                      key: 'phone',
                    },
                error: R.prop('requesterPhone', errors),
                item: isNewRequester ? (
                  <InputNumber
                    type="tel"
                    name="requesterPhone"
                    format="(###) ###-####"
                    mask="_"
                    placeholder="Enter Requester Phone"
                    value={R.prop('phone', newRequester)}
                    onChange={onNewRequesterChange('phone')}
                    invalid={!!R.prop('requesterPhone', errors)}
                  />
                ) : (
                  <InputNumber
                    type="tel"
                    name="requesterPhone"
                    format="(###) ###-####"
                    mask="_"
                    placeholder="Enter Requester Phone"
                    value={R.prop('cellPhone', selectedRequester) || '—'}
                    disabled
                  />
                ),
              },
              {
                title: {
                  key: 'requester',
                },
                item: isNewRequester ? (
                  <RequesterFields
                    title="New requester will be added when WO is created"
                    onClick={toggleIsNewRequester}
                    text="Cancel"
                  />
                ) : (
                  <RequesterFields
                    title="Didn't find the requester?"
                    onClick={toggleIsNewRequester}
                    text="+ Add Requester"
                  />
                ),
              },
            ]}
          />
        </Form>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 450 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">Save Changes</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

EditProject.propTypes = {
  canSelectPriority: PropTypes.bool.isRequired,
  requesters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
  requestersLoading: PropTypes.bool.isRequired,
  newRequester: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  activeProjectId: PropTypes.string.isRequired,
  editProjectModal: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      description: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      details: PropTypes.shape({ ops: PropTypes.array }),
      location: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool.isRequired,
  propertyLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    server: PropTypes.string,
    service: PropTypes.string,
    priorityId: PropTypes.string,
    property: PropTypes.string,
    description: PropTypes.string,
  }),
  isModalOpen: PropTypes.bool.isRequired,
  isNewRequester: PropTypes.bool.isRequired,
  onNewRequesterChange: PropTypes.func.isRequired,
  toggleIsNewRequester: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  serviceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedPriority: PropTypes.string,
  selectedStatus: PropTypes.string,
  selectedService: PropTypes.string,
  selectedProperty: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedRequester: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
  }),
  selectStatus: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  selectPriority: PropTypes.func.isRequired,
  selectProperty: PropTypes.func.isRequired,
  selectRequester: PropTypes.func.isRequired,
  setPropertySearch: PropTypes.func.isRequired,
  setSearchRequesters: PropTypes.func.isRequired,
  serviceLoading: PropTypes.bool.isRequired,
  setServiceSearch: PropTypes.func.isRequired,
  noPropertyResults: PropTypes.string,
  noRequesterResults: PropTypes.string,
  setNewRequester: PropTypes.func.isRequired,
};

EditProject.defaultProps = {
  requesters: [],
  properties: [],
  serviceTypes: [],
  selectedService: null,
  selectedPriority: null,
  selectedStatus: null,
  selectedProperty: null,
  editProjectModal: null,
  selectedRequester: null,
  errors: {},
  noRequesterResults: null,
  noPropertyResults: null,
};

export default EditProject;
