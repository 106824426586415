import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { DESC_SORT_ORDER } from '@poly/constants/src/misc.js';
import { Loader } from '@poly/site-ui/src/components/Loader.js';
import { mapConfigTableProps } from '@poly/site-ui/src/utils/table.js';
import { useTableSortingWrapper } from '@poly/site-ui/src/hooks/useTableSortingWrapper.js';
import {
  commonSortQuery,
  keywordSortQuery,
} from '@poly/client-utils/src/sorting.js';
import { Table } from '@poly/site-ui/src/components/Table.js';
import Widget from '@poly/site-book/src/Widget/index.js';

import {
  mrProjectFormatter,
  recurringProjectStatusUITransform,
} from '../../../util/project-utils.js';
import {
  fieldFormatter,
  formatDateWithDashes,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import { usePropertyRecurringProjects } from '../../../hooks/property/projects.js';
import { commonTablePropTypes } from '../../../components/common-prop-types.js';

function PropertyRecurringProjectsTableComp(props) {
  return (
    <Widget.Item>
      <Table
        gridColumns="
        minmax(75px, 150px)
        minmax(150px, 1fr)
        repeat(2, minmax(75px, 100px))
        minmax(80px, 160px)
      "
        {...props}
      />
    </Widget.Item>
  );
}

PropertyRecurringProjectsTableComp.propTypes = commonTablePropTypes;

const dateFormatter = (value) => fieldFormatter(formatDateWithDashes(value));

const projectsColumns = [
  [
    'WO #',
    R.prop('projectId'),
    mrProjectFormatter,
    R.prop('projectId'),
    keywordSortQuery(['projectId']),
  ],
  [
    'Description',
    R.prop('description'),
    fieldFormatter,
    R.prop('description'),
    keywordSortQuery(['description']),
  ],
  [
    'Start Date',
    R.prop('startDate'),
    dateFormatter,
    R.prop('startDate'),
    commonSortQuery(['startDate']),
  ],
  [
    'End Date',
    R.prop('endDate'),
    dateFormatter,
    R.prop('endDate'),
    commonSortQuery(['endDate']),
  ],
  [
    'Status',
    R.identity,
    recurringProjectStatusUITransform,
    R.prop('status'),
    commonSortQuery(['status']),
  ],
];

export function PropertyRecurringProjectsTable(props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: projectsColumns,
    defaultOrder: DESC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { loading, projects } = usePropertyRecurringProjects({
    propertyId,
    sort,
  });

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('Projects')();

  const tableProps = mapConfigTableProps(projects, projectsColumns);

  return (
    <PropertyRecurringProjectsTableComp
      {...props}
      {...tableProps}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
