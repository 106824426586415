import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { NOTHING_UI_STRING } from '@poly/constants';
import { AbsoluteLoader } from '@poly/site-ui';
import { ProjectPriority } from '../../components/projects/priority.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  dateFormatter,
  SelectDateRangeMessage,
} from '../../utils/projects/index.js';
import { useProjectsQuery } from '../../hooks/projects.js';
import { useCompletedWOsFilters } from './completed-wo-filters.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import { noDataToDisplay, isExist } from '../../util/general.js';
import usePagination from '../../hooks/usePagination.js';
import { onTimeFormatter } from './export-to-xls.js';
import {
  setCompletedWOsPaginationCurrentPage,
  setCompletedWOsPaginationDropdownLabel,
  setCompletedWOsPaginationPageSize,
  setCompletedWOsPaginationTotal,
} from '../../redux/actions/index.js';
import {
  highlightSupplier,
  highlightProject,
  highlightField,
} from '../../utils/highlight/index.js';

// highlightSupplierIfExists :: String -> Supplier -> String
const highlightSupplierIfExists = (search) =>
  R.ifElse(isExist, highlightSupplier(search), R.always(NOTHING_UI_STRING));

const getColumns = (search) => [
  ['WO #', R.identity, highlightProject(search)],
  ['Description', R.prop('description'), highlightField(search)],
  ['Service Type', R.path(['serviceType', 'name']), highlightField(search)],
  [
    'Priority',
    R.path(['priority', 'id']),
    (priorityId) => <ProjectPriority priorityId={priorityId} search={search} />,
  ],
  ['Start Date', R.prop('startDate'), dateFormatter(search)],
  ['Due Date', R.prop('endDate'), dateFormatter(search)],
  ['Completion Date', R.prop('workCompletionDate'), dateFormatter(search)],
  ['On-time', onTimeFormatter, highlightField(search)],
  [
    'Technician',
    R.path(['technicians', 0, 'fullName']),
    highlightField(search),
  ],
  ['Manager', R.path(['manager', 'fullName']), highlightField(search)],
  ['Supplier', R.path(['suppliers', 0]), highlightSupplierIfExists(search)],
  ['Property', R.path(['property', 'name']), highlightField(search)],
];

export default function (props) {
  const dispatch = useDispatch();

  const completedProjects = useSelector((state) => state.completedProjects);

  const {
    currentPage,
    pageSize,
    dateFilter,
    serviceTypeId,
    tableSearchString,
    paginationDropdownLabel,
    typeFilter,
  } = completedProjects;

  const startDate = dateFilter?.startDate;
  const endDate = dateFilter?.endDate;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, sort } = useCompletedWOsFilters({
    ...props,
    startDate,
    endDate,
    serviceTypeId,
    tableSearchString,
  });

  const { projects, total, restProjectsProps, result } = useProjectsQuery({
    searchTerm,
    query,
    pageSize,
    from,
    sort,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setCompletedWOsPaginationTotal,
    setCurrent: setCompletedWOsPaginationCurrentPage,
    setPageSize: setCompletedWOsPaginationPageSize,
    setPaginationDropdownLabel: setCompletedWOsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(tableSearchString));
  const headers = R.map(R.nth(0), getColumns(tableSearchString));

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    getColumns(tableSearchString),
  );

  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(tableSearchString),
  );

  const colsPropsValue = R.map(R.nth(3), getColumns(tableSearchString));

  const gridColumns = `
      80px
      minmax(80px, 1fr)
      repeat(2, minmax(70px, 130px))
      minmax(70px, 100px)
      80px
      minmax(70px, 140px)
      80px
      minmax(70px, 130px)
      repeat(3, minmax(80px, 1fr))
    `;

  const { rows, sorting, setSorting } = useSortableTable(
    projects,
    valuesToSort,
  );

  if (!startDate && !endDate) return <SelectDateRangeMessage />;
  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      {...restProjectsProps}
      props={colsPropsValue}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      sorting={sorting}
      setSorting={setSorting}
      dispatch={dispatch}
      serviceTypeId={serviceTypeId}
      valuesToSort={valuesToSort}
      typeFilter={typeFilter}
      searchTerm={tableSearchString}
    />
  );
}
