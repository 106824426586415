import { useQuery, gql } from '@apollo/client';
import * as R from 'ramda';

const SEARCH_ASSETS_QUERY = gql`
  query SEARCH_ASSETS_QUERY($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        description
        equipmentType
        manufacturer
        model
        serial
        warrantyEnd
      }
      total
    }
  }
`;

export const usePropertyAssets = ({ propertyId, sort }) => {
  const { data, loading } = useQuery(SEARCH_ASSETS_QUERY, {
    alias: 'usePropertyAssets',
    variables: {
      input: { query: { term: { propertyId } }, sort, size: 10000 },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    assetsLoading: loading,
    assets: R.pathOr([], ['searchAssets', 'hits'], data),
  };
};
