import React from 'react';
import * as R from 'ramda';
import Link from 'redux-first-router-link';
import { highlightTextToReactElement } from '@poly/client-utils';
import { L } from '@poly/site-book';
import {
  RecurringProjectStatuses,
  HREF_PLACEHOLDER,
  ProjectType,
} from '@poly/constants';
import {
  EntityStatusFormatter,
  PROJECT_STATUS_COLORS,
  isEndDateLessToday,
} from '@poly/site-ui';

import {
  PROJECT,
  PROPERTY,
  PM_PROJECT,
  MR_PROJECT,
  MR_PM_PROJECT,
} from '../constants/routes.js';
import {
  recurringProjectStatusesText,
  recurringProjectStatusesColors,
} from '../constants/recurring-projects.js';
import { fieldFormatter, isExist } from './general.js';

const { pastDue } = PROJECT_STATUS_COLORS;

const A = L.withComponent('span');

export const propertyFormatter = (property) => {
  const { _id: id, name } = property;

  return (
    // eslint-disable-next-line
    <Link to={{ type: PROPERTY, payload: { id } }}>
      <A bold>{name}</A>
    </Link>
  );
};

export const projectFormatter = (id, search) => (
  // eslint-disable-next-line
  <Link to={{ type: PROJECT, payload: { id } }}>
    <A bold>{isExist(search) ? highlightTextToReactElement(search, id) : id}</A>
  </Link>
);

export const mrProjectFormatter = (id, search) => (
  // eslint-disable-next-line
  <Link to={{ type: MR_PROJECT, payload: { id } }}>
    <A bold>{isExist(search) ? highlightTextToReactElement(search, id) : id}</A>
  </Link>
);

export const mrPmProjectFormatter = (project, search) => {
  const { _id: id, maintenancePlanId } = project;
  return (
    // eslint-disable-next-line
    <Link to={{ type: MR_PM_PROJECT, payload: { id } }}>
      <A bold>
        {isExist(search)
          ? highlightTextToReactElement(search, maintenancePlanId)
          : maintenancePlanId}
      </A>
    </Link>
  );
};

// eslint-disable-next-line
export const projectPMFormatter = ({ _id, projectId }, search) => (
  // eslint-disable-next-line
  <Link to={{ type: PM_PROJECT, payload: { id: projectId || _id } }}>
    <A bold>
      {isExist(search)
        ? highlightTextToReactElement(search, projectId)
        : projectId}
    </A>
  </Link>
);

export const linkFormatter = ({
  search,
  bold,
  target,
  href = HREF_PLACEHOLDER,
}) =>
  function (value) {
    return value ? (
      <L {...{ bold, target, href }} key={value}>
        {isExist(search) ? highlightTextToReactElement(search, value) : value}
      </L>
    ) : (
      fieldFormatter(value, search)
    );
  };

export const emailLinkFormatter = (props) => (email) =>
  linkFormatter({ ...props, href: `mailto:${email}` })(email);

const getFullName = R.path(['createdBy', 'fullName']);

export const getUpdateSource = R.ifElse(
  getFullName,
  getFullName,
  R.always('Poly'),
);

// getProjectStatusColor :: Project -> String
const getRecurringProjectStatusColor = R.cond([
  [
    R.both(
      R.propSatisfies(
        R.includes(R.__, [RecurringProjectStatuses.ACTIVE]),
        'status',
      ),
      isEndDateLessToday,
    ),
    R.always(pastDue),
  ],
  [
    R.T,
    R.compose(R.prop(R.__, recurringProjectStatusesColors), R.prop('status')),
  ],
]);

export const recurringProjectStatusUITransform = R.converge(
  EntityStatusFormatter,
  [
    getRecurringProjectStatusColor,
    R.compose(R.prop(R.__, recurringProjectStatusesText), R.prop('status')),
    R.prop('search'),
  ],
);

const projectTypes = {
  [ProjectType.WORK_ORDER]: 'Reactive',
  [ProjectType.PREVENTIVE_MAINTENANCE]: 'PM',
};

/**
 * clientPrioritiesToPrioritiesSelect :: [ClientPriorities] -> PriorityData
 * ClientPriorities = { PriorityName: Number }
 * PriorityData = { value: String }
 */
export const clientPrioritiesToPrioritiesSelect = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('id'),
      label: R.prop('name'),
    }),
  ),
  R.defaultTo([]),
);

export const projectTypeConverter = (search) => (type) => {
  const value = projectTypes[type] || type;
  return fieldFormatter(value, search);
};

// getTechnicianTypeByUser :: Technician -> String
export const getTechnicianTypeByUser = R.pathOr('-', [
  'userGroups',
  0,
  'userGroup',
  'name',
]);
