import * as R from 'ramda';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useSelector } from 'react-redux';

import Select from '../../select/index.js';
import { formatPlaceholder } from '../../../util/select.js';
import { ProjectPriority } from '../../projects/priority.js';
import { clientPrioritiesToPrioritiesSelect } from '../../../util/project-utils.js';

function ProjectPriorityWrapper({ value }) {
  return <ProjectPriority priorityId={value} />;
}

ProjectPriorityWrapper.propTypes = {
  value: PropTypes.string.isRequired,
};

function SelectPriorityComponent({
  selectedPriority,
  selectPriority,
  errors,
  options,
}) {
  return (
    <Select
      value={selectedPriority}
      onChange={selectPriority}
      placeholder={formatPlaceholder('Priority')}
      options={options}
      invalid={!!R.prop('priorityId', errors)}
      optionRenderer={ProjectPriorityWrapper}
      clearable={false}
      autoFocus
    />
  );
}

SelectPriorityComponent.propTypes = {
  selectedPriority: PropTypes.string,
  selectPriority: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    priorityId: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

function SelectPriority(props) {
  const projectPriorities = useSelector(
    (state) => state.user.clientConfig?.projectPriorities,
  );

  const options = clientPrioritiesToPrioritiesSelect(projectPriorities);

  return <SelectPriorityComponent {...props} options={options} />;
}

SelectPriority.propTypes = {
  selectedPriority: PropTypes.string,
  selectPriority: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    priorityId: PropTypes.string,
  }),
};

SelectPriority.displayName = 'SelectPriority';

export const projectPriorityField = ({
  selectedPriority,
  selectPriority,
  errors,
}) => ({
  title: {
    value: 'Priority',
    props: ['required'],
  },
  error: R.prop('priorityId', errors),
  item: <SelectPriority {...{ errors, selectedPriority, selectPriority }} />,
});
