import * as R from 'ramda';
import { isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { WorkOrderPriority } from '@poly/constants';
import { ensureIsDate } from '@poly/utils';
import { ALERTS } from '@poly/site-ui';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';

import { formatDateIfExists } from '../../util/general.js';

// onTimeFormatter :: Project -> String
export const onTimeFormatter = ({ endDate, workCompletionDate }) =>
  isBefore(ensureIsDate(workCompletionDate), ensureIsDate(endDate))
    ? 'On-time'
    : 'Late';

const COLUMN_WIDTH = [15, 25, 20, 10, 15, 15, 15, 10, 25, 25, 25, 25];

const TITLE_ROW = [
  'WO #',
  'Description',
  'Service Type',
  'Priority',
  'Start Date',
  'Due Date',
  'Completion Date',
  'On-Time',
  'Technician',
  'Manager',
  'Supplier',
  'Property',
];
// getCompletedWORowConfig :: Project -> [ExcelExportDataCell]
const getCompletedWORowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 12),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['serviceType', 'name']),
    R.pathOr('', ['priority', 'name']),
    R.compose(formatDateIfExists, R.prop('startDate')),
    R.compose(formatDateIfExists, R.prop('endDate')),
    R.compose(formatDateIfExists, R.prop('workCompletionDate')),
    onTimeFormatter,
    R.pathOr('', ['technicians', 0, 'fullName']),
    R.pathOr('', ['manager', 'fullName']),
    R.pathOr('', ['suppliers', 0, 'company', 'name']),
    R.pathOr('', ['property', 'name']),
  ]),
);

// getCompletedWOExcelConfig :: Bool -> [Project] -> Object
export const getCompletedWOExcelConfig = (isOnHold) => (projects) => ({
  exportFileName: isOnHold
    ? 'completed_on_hold_wo_export.xlsx'
    : 'completed_wo_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 12)],
      TITLE_ROW,
    ),
    ...R.map(getCompletedWORowConfig, projects),
  ],
});

export const exportCompletedWOToXLS = (isOnHold) =>
  R.compose(
    performExcelExport,
    getCompletedWOExcelConfig(isOnHold),
    R.path(['data', 'searchProjects', 'hits']),
  );

// getCompletedWOStatsRow :: WOStats -> [ExcelExportDataCell]
// WOStats = Object
const getCompletedWOStatsRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.propOr('', WorkOrderPriority.EMERGENCY),
    R.propOr('', WorkOrderPriority.URGENT),
    R.propOr('', WorkOrderPriority.NORMAL),
    R.propOr('', WorkOrderPriority.PLANNED),
    R.propOr('', 'total'),
  ]),
);

// getExportCompletedWOStatsConfig :: ([String], [WOStats], Boolean) -> ExcelExportConfig
// ExcelExportConfig = Object
// WOStats = Object
const getExportCompletedWOStatsConfig = (headers, rows, isOnHold) => ({
  exportFileName: isOnHold
    ? 'completed_on_hold_wo_stats_export.xlsx'
    : 'completed_wo_stats_export.xlsx',
  columnWidths: [35, 25, 25, 25, 25, 25],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 6)],
      R.map(R.toUpper)(headers),
    ),
    ...R.map(getCompletedWOStatsRow)(rows),
  ],
});

// exportCompletedWOStatsToXLS :: Boolean -> TableProps -> Boolean
// TableProps -> Object
export const exportCompletedWOStatsToXLS =
  (isOnHold) =>
  ({ headers, rows }) => {
    if (!rows) {
      toast.error(ALERTS.NoDataToDisplay);
      return false;
    }
    const exportConfig = getExportCompletedWOStatsConfig(
      headers,
      rows,
      isOnHold,
    );
    performExcelExport(exportConfig);
    return true;
  };
