import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DESC_SORT_ORDER } from '@poly/constants/src/misc.js';
import { POST_PO_INVOICE_PERMISSION } from '@poly/security/src/permissions.js';
import { AbsoluteLoader } from '@poly/site-ui/src/components/Loader.js';
import { mapConfigTableProps } from '@poly/site-ui/src/utils/table.js';
import { useTableSortingWrapper } from '@poly/site-ui/src/hooks/useTableSortingWrapper.js';
import {
  commonSortQuery,
  keywordSortQuery,
} from '@poly/client-utils/src/sorting.js';

import { PostInvoiceError } from '../../constants/alerts.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import TableCheckbox from '../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../components/checkboxes/header-checkbox.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import { InvoiceFormatter } from '../../utils/invoices/index.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
  scopeFormatter,
} from '../../utils/purchase-orders/index.js';
import {
  setApprovedInvoicesPaginationCurrentPage,
  setApprovedInvoicesPaginationDropdownLabel,
  setApprovedInvoicesPaginationPageSize,
  setApprovedInvoicesPaginationTotal,
  setApprovedSelectedInvoices,
  toggleApprovedInvoice,
} from '../../redux/actions/index.js';
import { useInvoicesQuery } from '../../hooks/invoices.js';

const STATE_PATH = ['approvedInvoices', 'selectedInvoices'];

const getColumns = ({
  invoices,
  dispatch,
  tableSearchString: search,
  user,
}) => {
  const commonProps = {
    disabled: !checkPermissionByOneAccessItem(
      POST_PO_INVOICE_PERMISSION,
      user,
      R.pick(['clientId'], user),
    ),
    showErrorMessage: () => toast.error(PostInvoiceError),
  };
  return [
    [
      <HeaderCheckbox
        statePath={STATE_PATH}
        pageItems={invoices.map(({ _id }) => _id)}
        onChange={R.compose(dispatch, setApprovedSelectedInvoices)}
        {...commonProps}
      />,
      R.prop('_id'),
      (id) => (
        <TableCheckbox
          statePath={STATE_PATH}
          onChange={R.compose(dispatch, toggleApprovedInvoice)}
          id={id}
          {...commonProps}
        />
      ),
    ],
    [
      'PO#',
      R.prop('purchaseOrder'),
      POFormatter(search),
      R.path(['purchaseOrder', 'cardNumber']),
      keywordSortQuery(['purchaseOrder', 'cardNumber']),
    ],
    [
      'Invoice#',
      R.identity,
      InvoiceFormatter(search),
      R.prop('invoiceNumber'),
      keywordSortQuery(['invoiceNumber']),
    ],
    [
      'Amount',
      R.prop('total'),
      highlightCurrency(search),
      R.prop('total'),
      commonSortQuery(['total']),
    ],
    [
      'Scope',
      R.path(['purchaseOrder', 'isInScope']),
      scopeFormatter(search),
      R.path(['purchaseOrder', 'isInScope']),
      commonSortQuery(['purchaseOrder', 'isInScope']),
    ],
    [
      'WO#',
      R.prop('project'),
      projectPOFormatter(search),
      R.path(['project', 'projectId']),
      keywordSortQuery(['projectNumber']),
    ],
    [
      'Supplier',
      R.path(['supplier', 'company', 'name']),
      highlightField(search),
      R.path(['supplier', 'company', 'name']),
      keywordSortQuery(['supplier', 'company', 'name']),
    ],
    [
      'Property',
      propertySelector,
      highlightField(search),
      R.path(['property', 'name']),
      keywordSortQuery(['property', 'name']),
    ],
  ];
};

export default function (props) {
  const dispatch = useDispatch();

  const approvedInvoices = useSelector((state) => state.approvedInvoices);
  const user = useSelector((state) => state.user);

  const {
    type,
    currentPage,
    pageSize,
    tableSearchString,
    paginationDropdownLabel,
  } = approvedInvoices;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, size } = useInvoicesFilters(approvedInvoices);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: getColumns({ invoices: [], user, dispatch }),
    defaultOrder: DESC_SORT_ORDER,
    defaultColumn: 2,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size, from, sort });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setApprovedInvoicesPaginationTotal,
    setPageSize: setApprovedInvoicesPaginationPageSize,
    setCurrent: setApprovedInvoicesPaginationCurrentPage,
    setPaginationDropdownLabel: setApprovedInvoicesPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = getColumns({ invoices, dispatch, tableSearchString, user });

  const tableProps = mapConfigTableProps(invoices, cols);

  const gridColumns = `
        16px
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        100px
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  if (invoicesLoading) return <AbsoluteLoader />;
  if (!invoices.length)
    return noDataToDisplay('Invoices Ready for AP Posting')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...tableProps}
      {...restInvoicesProps}
      user={user}
      type={type}
      from={from}
      total={total}
      size={pageSize}
      rows={invoices}
      sorting={sorting}
      onChange={onChange}
      omitSorting={[true]}
      setSorting={setSorting}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
      currentPage={currentPage}
      valuesToSort={valuesToSort}
      showPagination={showPagination}
      invoicesLoading={invoicesLoading}
      onShowSizeChange={onShowSizeChange}
      tableSearchString={tableSearchString}
      paginationDropdownLabel={paginationDropdownLabel}
    />
  );
}
