import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { S } from '@poly/site-book';
import PropTypes from 'prop-types';
import { highlightTextToReactElement } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import { isExist } from '../../util/general.js';

function PriorityComponent({ text, search, textColor }) {
  return (
    <S type="title" textColor={textColor}>
      {isExist(search) ? highlightTextToReactElement(search, text) : text}
    </S>
  );
}

PriorityComponent.propTypes = {
  textColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  search: PropTypes.string,
};

// getClientProjectPriority :: String -> [ProjectPriorityConfigEntry] -> ProjectPriorityConfigEntry
const getClientProjectPriority = (priorityId) =>
  R.compose(
    R.defaultTo({}),
    R.find(propEqLegacy('id', priorityId)),
    R.defaultTo([]),
  );

export function ProjectPriority(props) {
  const projectPriorities = useSelector(
    (state) => state.user.clientConfig?.projectPriorities,
  );

  const { name, color, amount, unit } = getClientProjectPriority(
    props.priorityId,
  )(projectPriorities);

  const label = `${name} (${amount} ${unit})`;

  return (
    <PriorityComponent {...props} textColor={color || '#6f7a9f'} text={label} />
  );
}

ProjectPriority.propTypes = {
  priorityId: PropTypes.string.isRequired,
  search: PropTypes.string,
};

ProjectPriority.displayName = 'ProjectPriority';
