import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { AbsoluteLoader, useTableSortingWrapper } from '@poly/site-ui';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';

import Staff from '../../components/staff/index.js';
import { UserStaffLink } from '../../utils/staff/UserStaffLink.js';
import { getUserTypeName, statusFormatter } from '../../util/staff.js';
import { emailLinkFormatter } from '../../util/project-utils.js';
import { highlightField } from '../../utils/highlight/index.js';
import { getUserCellPhone } from '../../utils/staff/index.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import { useStaff } from '../../hooks/staff.js';
import {
  setStaffPaginationDropdownLabel,
  setStaffPaginationCurrentPage,
  setStaffPaginationPageSize,
  setStaffPaginationTotal,
} from '../../redux/actions/index.js';

const columns = (search) => [
  [
    'User',
    R.identity,
    (user) => <UserStaffLink user={user} searchText={search} />,
    R.path(['profile', 'fullName']),
    keywordSortQuery(['profile', 'fullName']),
  ],
  [
    'Type',
    getUserTypeName,
    highlightField(search),
    getUserTypeName,
    commonSortQuery(['userGroupsNamesStr']),
  ],
  [
    'Mobile',
    getUserCellPhone,
    highlightField(search),
    getUserCellPhone,
    commonSortQuery(['profile', 'cellPhoneNumber']),
  ],
  [
    'Email',
    R.prop('email'),
    emailLinkFormatter({ search }),
    R.prop('email'),
    commonSortQuery(['emailsStr']),
  ],
  ['Active', R.path(['activeProjectsCount', 'total']), highlightField(search)],
  [
    'Overdue',
    R.path(['overdueProjectsCount', 'total']),
    highlightField(search),
  ],
  [
    'Last 30',
    R.path(['lastThirtyProjectsCount', 'total']),
    highlightField(search),
  ],
  [
    'Status',
    R.identity,
    statusFormatter(search),
    R.prop('status'),
    commonSortQuery(['status']),
  ],
];

export default function (props) {
  const user = useSelector((state) => state.user);
  const staff = useSelector((state) => state.staff);

  const {
    pageSize,
    typeFilter,
    currentPage,
    tableSearchString,
    paginationDropdownLabel,
  } = staff;

  const from = (currentPage - 1) * pageSize;

  const clientId = user?.clientId;

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
    defaultOrder: ASC_SORT_ORDER,
    tableConfig: columns(tableSearchString),
  });

  const {
    data,
    skip,
    total,
    query,
    users,
    loading,
    refetch,
    restProps,
    unsubscribe,
    networkStatus,
    additionalVars,
    subscribeToMore,
  } = useStaff(
    { typeFilter, clientId },
    { from, pageSize, tableSearchString, sort },
  );

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    total,
    currentPage,
    setTotal: setStaffPaginationTotal,
    setPageSize: setStaffPaginationPageSize,
    setCurrent: setStaffPaginationCurrentPage,
    setPaginationDropdownLabel: setStaffPaginationDropdownLabel,
  });

  const cols = columns(tableSearchString);

  const columnsValue = R.map(R.nth(1), cols);

  const headers = R.map(R.nth(0), cols);

  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);

  const gridColumns = `
        repeat(3, 1fr)
        1.3fr
        repeat(3, 70px)
        100px
      `;

  const omitSorting = [false, false, false, false, true, true, true, false];

  if ((loading && networkStatus !== 4) || !clientId) return <AbsoluteLoader />;
  if (!users.length) return noDataToDisplay('Staff')();

  return (
    <Staff
      {...props}
      {...restProps}
      from={from}
      cols={cols}
      data={data}
      skip={skip}
      rows={users}
      total={total}
      query={query}
      users={users}
      size={pageSize}
      headers={headers}
      formats={formats}
      refetch={refetch}
      loading={loading}
      sorting={sorting}
      clientId={clientId}
      onChange={onChange}
      columns={columnsValue}
      typeFilter={typeFilter}
      setSorting={setSorting}
      omitSorting={omitSorting}
      currentPage={currentPage}
      gridColumns={gridColumns}
      unsubscribe={unsubscribe}
      valuesToSort={valuesToSort}
      networkStatus={networkStatus}
      searchTerm={tableSearchString}
      additionalVars={additionalVars}
      showPagination={showPagination}
      subscribeToMore={subscribeToMore}
      onShowSizeChange={onShowSizeChange}
      paginationDropdownLabel={paginationDropdownLabel}
    />
  );
}
