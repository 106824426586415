import * as R from 'ramda';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import { ensureIsDate } from '@poly/utils';
import { AbsoluteLoader } from '@poly/site-ui';
import { DEFAULT_CLIENT_PROJECT_PRIORITIES } from '@poly/constants';

import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { fieldFormatter, noDataToDisplay } from '../../util/general.js';
import { useProjectsStatsQuery } from '../../hooks/projects.js';
import { normalizeProjectPrioritiesToDays } from '../../utils/projects/priority.js';
import {
  checkInTime,
  convertToPercents,
  getAverageDays,
  SelectDateRangeMessage,
} from '../../utils/projects/index.js';
import { useCompletedWOsFilters } from './completed-wo-filters.js';

// getPrioritiesOffset :: { clientConfig: ClientConfigs } -> [ProjectPriorityConfigEntry]
const getPrioritiesOffset = R.compose(
  normalizeProjectPrioritiesToDays,
  R.pathOr(DEFAULT_CLIENT_PROJECT_PRIORITIES, [
    'clientConfig',
    'projectPriorities',
  ]),
);

// getTimeByPriority :: Array -> Object
const getTimeByPriority = R.compose(
  R.mapObjIndexed((projects) =>
    convertToPercents(checkInTime(projects).length, projects.length),
  ),
  R.groupBy(R.path(['priority', 'id'])),
);

// getAllInTime :: Array -> Number
const getAllInTime = R.compose(
  R.length,
  R.filter((project) =>
    isAfter(
      ensureIsDate(project.endDate),
      ensureIsDate(project.workCompletionDate),
    ),
  ),
);

// getAverageByPriority :: Array -> Object
const getAverageByPriority = R.compose(
  R.map(getAverageDays),
  R.groupBy(R.path(['priority', 'id'])),
);

const getColumns = (priorities) => [
  ['Report', R.prop('name'), fieldFormatter],
  ...priorities.map(({ id, name }) => [name, R.prop(id), fieldFormatter]),
  ['Total', R.prop('total'), fieldFormatter],
];

export default function (props) {
  const dispatch = useDispatch();

  const completedProjects = useSelector((state) => state.completedProjects);
  const user = useSelector((state) => state.user);

  const priorities = getPrioritiesOffset(user);

  const {
    dateFilter,
    locationType,
    typeFilter,
    tableSearchString,
    serviceTypeId,
  } = completedProjects;

  const startDate = dateFilter?.startDate;
  const endDate = dateFilter?.endDate;

  const { searchTerm, query, sort } = useCompletedWOsFilters({
    ...props,
    startDate,
    endDate,
    serviceTypeId,
    tableSearchString,
  });

  const { stats, statsLoading, statsTotal, restProjectsProps } =
    useProjectsStatsQuery({ searchTerm, query, sort });

  if (!startDate && !endDate) return <SelectDateRangeMessage />;
  if (statsLoading) return <AbsoluteLoader />;
  if (!stats.length) return noDataToDisplay('Stats')();

  const completedByPriority = R.reduceBy(
    R.inc,
    0,
    R.path(['priority', 'id']),
    stats,
  );

  const onTimeByPriority = getTimeByPriority(stats);

  const allInTime = getAllInTime(stats);

  const averageByPriority = getAverageByPriority(stats);

  const modifiedStats = [
    {
      _id: uuidV4(),
      name: "WO's Completed",
      ...completedByPriority,
      total: statsTotal,
    },
    {
      _id: uuidV4(),
      name: '% On Time',
      ...onTimeByPriority,
      total: convertToPercents(allInTime, statsTotal),
    },
    {
      _id: uuidV4(),
      name: 'Average Days',
      ...averageByPriority,
      total: getAverageDays(stats),
    },
  ];

  const columns = R.map(R.nth(1), getColumns(priorities));
  const headers = R.map(R.nth(0), getColumns(priorities));

  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(priorities),
  );

  const gridColumns = 'repeat(6, minmax(100px, 1fr))';

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restProjectsProps}
      rows={modifiedStats}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      dispatch={dispatch}
      serviceTypeId={serviceTypeId}
      typeFilter={typeFilter}
      locationType={locationType}
      searchTerm={tableSearchString}
    />
  );
}
