import React from 'react';
import { Table } from '@poly/site-ui';
import { Widget } from '@poly/site-book';

import { commonTablePropTypes } from '../../common-prop-types.js';

export function WorkOrdersTabTable(props) {
  return (
    <Widget.Item>
      <Table
        gridColumns="
        minmax(75px, 150px)
        minmax(150px, 1fr)
        minmax(75px, 150px)
        minmax(80px, 160px)
      "
        {...props}
      />
    </Widget.Item>
  );
}

WorkOrdersTabTable.propTypes = commonTablePropTypes;
