import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getValue } from '../../util/general.js';
import {
  useManagers,
  useTechnicians,
  useRequesters,
} from '../../hooks/staff.js';
import { useSuppliers } from '../../hooks/suppliers.js';
import { useServiceTypes } from '../../hooks/projects.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { useMailTo } from '../../utils/projects/projectCommon.js';
import AddWorkOrder from '../../components/project/add-work-order.js';
import { omitBlockedSuppliersQuery } from '../../utils/suppliers/suppliers-filter.js';
import { useCreateWOPartLogic, useCreateWOMutationHandlers } from './hooks.js';
import { getRequestDataForWO } from './helpers.js';

export default function (props) {
  const dispatch = useDispatch();

  const {
    typeFilter,
    clientId,
    supplierSource,
    selectedPriority,
    selectedProperty,
    selectedService,
    selectedRequester,
    projectTechnician,
    projectManager,
    projectSuppliers,
    setLoading,
    isNewRequester,
    search,
    searchTerm,
    searchService,
    searchRequesters,
    errors,
    setError,
    isProjectAssigned,
    validate,
    ...restCreateWOProps
  } = useCreateWOPartLogic();

  const { serviceTypes, serviceLoading } = useServiceTypes(
    supplierSource,
    searchService,
  );

  const { technicians } = useTechnicians(searchTerm, clientId);
  const { managers } = useManagers(searchTerm, clientId);

  const { allSuppliers } = useSuppliers({
    searchTerm,
    query: omitBlockedSuppliersQuery,
  });

  const { requesters, requestersLoading, noRequesterResults } =
    useRequesters(searchRequesters);

  const { propertyLoading, properties, noPropertyResults } = usePropertiesQuery(
    search,
    typeFilter,
  );

  const { selectAdditionalEmails, additionalEmails } = useMailTo(
    errors,
    setError,
  );

  const { setRef, getRef } = useRefHandlers();

  const { finalMutation, createContactUserMutation } =
    useCreateWOMutationHandlers({
      selectedProperty,
      setLoading,
      setError,
    });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const requesterName = getValue('requesterName', e)?.trim();
      const requesterPhone = getValue('requesterPhone', e);
      const requesterEmail = getValue('requesterEmail', e);

      const errorsValue = validate({
        service: selectedService,
        priorityId: selectedPriority,
        description: getValue('description', e),
        property: selectedProperty?.value,
        requesterName,
        requesterPhone,
        requesterEmail,
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const requestData = getRequestDataForWO({
        clientId,
        selectedPriority,
        selectedService,
        selectedProperty,
        selectedRequester,
        getRef,
        additionalEmails,
        projectManager,
        projectSuppliers,
        projectTechnician,
        event: e,
      });

      return isNewRequester
        ? createContactUserMutation({
            requesterName,
            requesterEmail,
            requesterPhone,
            requestData,
          })
        : finalMutation({ requestData });
    },
    [
      selectedPriority,
      selectedProperty,
      selectedService,
      selectedRequester,
      getRef,
      dispatch,
      setError,
      validate,
      setLoading,
      clientId,
      projectManager,
      projectSuppliers,
      projectTechnician,
      isProjectAssigned,
      isNewRequester,
      additionalEmails,
    ],
  );

  return (
    <AddWorkOrder
      {...props}
      {...restCreateWOProps}
      isAdd
      setRef={setRef}
      isProjectAssigned={isProjectAssigned}
      showMailTo={isProjectAssigned}
      propertyLoading={propertyLoading}
      requestersLoading={requestersLoading}
      serviceLoading={serviceLoading}
      selectedPriority={selectedPriority}
      selectedProperty={selectedProperty}
      selectedService={selectedService}
      selectedRequester={selectedRequester}
      serviceTypes={serviceTypes}
      properties={properties}
      onSubmit={onSubmit}
      managers={managers}
      technicians={technicians}
      allSuppliers={allSuppliers}
      projectTechnician={projectTechnician}
      projectManager={projectManager}
      projectSuppliers={projectSuppliers}
      errors={errors}
      isNewRequester={isNewRequester}
      requesters={requesters}
      additionalEmails={additionalEmails}
      noPropertyResults={noPropertyResults}
      noRequesterResults={noRequesterResults}
      selectAdditionalEmails={selectAdditionalEmails}
    />
  );
}
