import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Loader,
  mapConfigTableProps,
  useTableSortingWrapper,
} from '@poly/site-ui';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { Table } from '@poly/site-ui/src/components/Table.js';
import { Widget } from '@poly/site-book';

import {
  noDataToDisplayWidget,
  fieldFormatter,
} from '../../../util/general.js';
import { assetLinkFormatter } from '../../../util/assets/formatters.js';
import { warrantyEndFormatter } from '../../../utils/assets.js';
import { usePropertyAssets } from '../../../hooks/property/usePropertyAssets.js';
import { commonTablePropTypes } from '../../../components/common-prop-types.js';

function PropertyAssetsTableComp(props) {
  return (
    <Widget.Item>
      <Table
        gridColumns="
        minmax(50px, 150px)
        minmax(100px, 300px)
        minmax(100px, 200px)
        minmax(75px, 170px)
        minmax(75px, 150px)
        minmax(50px, 80px)
      "
        {...props}
      />
    </Widget.Item>
  );
}

PropertyAssetsTableComp.propTypes = commonTablePropTypes;

const assetsTableConfig = [
  [
    'Equipment Type',
    R.prop('equipmentType'),
    fieldFormatter,
    R.prop('equipmentType'),
    keywordSortQuery(['equipmentType']),
  ],
  [
    'Description',
    R.prop('description'),
    fieldFormatter,
    R.prop('description'),
    keywordSortQuery(['description']),
  ],
  [
    'Manufacturer',
    R.prop('manufacturer'),
    fieldFormatter,
    R.prop('manufacturer'),
    keywordSortQuery(['manufacturer']),
  ],
  [
    'Model',
    R.prop('model'),
    fieldFormatter,
    R.prop('model'),
    keywordSortQuery(['model']),
  ],
  [
    'Serial Number',
    R.identity,
    assetLinkFormatter,
    R.prop('serial'),
    keywordSortQuery(['serial']),
  ],
  [
    'Warranty Exp',
    R.prop('warrantyEnd'),
    warrantyEndFormatter(),
    R.prop('warrantyEnd'),
    commonSortQuery(['warrantyEnd']),
  ],
];

export function PropertyAssetsTable(props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: assetsTableConfig,
    defaultOrder: ASC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { assetsLoading, assets } = usePropertyAssets({ propertyId, sort });

  if (assetsLoading) return <Loader />;
  if (!assets.length) return noDataToDisplayWidget('Assets')();

  const tableProps = mapConfigTableProps(assets, assetsTableConfig);

  return (
    <PropertyAssetsTableComp
      {...props}
      {...tableProps}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
