import { useQuery } from '@apollo/client';
import {
  getPropertyRecurringProjectsQuery,
  getPropertyProjectsQuery,
  searchProjectChanged,
} from '../../queries/index.js';
import { WORK_ORDER } from '../../constants/projects.js';
import { MAX_SIZE } from '../../constants/index.js';
import { useReactiveEntities } from '../useReactiveEntities.js';

export const usePropertyProjects = ({ propertyId, sort }) => {
  const query = {
    bool: {
      must: { match: { type: WORK_ORDER } },
    },
  };

  const { data, loading } = useReactiveEntities({
    gqlQuery: getPropertyProjectsQuery,
    gqlChangedQuery: searchProjectChanged,
    additionalVars: { propertyId },
    query,
    size: MAX_SIZE,
    alias: 'usePropertyProjects',
    sort,
  });

  return {
    projectsLoading: loading,
    projects: data?.property.searchProjects?.hits || [],
  };
};

export const usePropertyRecurringProjects = ({ propertyId, sort }) => {
  const { data, loading } = useQuery(getPropertyRecurringProjectsQuery, {
    alias: 'usePropertyRecurringProjects',
    variables: {
      propertyId,
      input: { size: MAX_SIZE, sort },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    projects: data?.property?.searchRecurringProjects?.hits,
  };
};
