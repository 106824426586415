import { gql } from '@apollo/client';

import { supplierCompanyFields } from './suppliers/fragments.js';

export { updateDetailsFragment as updateFragment } from '@poly/site-ui';

export const fileFragment = gql`
  fragment fileFragment on File {
    _id
    fileType
    fileName
    createdAt
    url
  }
`;

export const invoiceFragment = gql`
  fragment invoiceFragment on POInvoice {
    _id
    invoiceNumber
    invoiceFile {
      url
      fileName
      fileSize
    }
    receiveDate
    invoiceDate
    total
    glCode
    comments
    status
  }
`;

export const purchaseOrderFragment = gql`
  fragment purchaseOrderFragment on PurchaseOrder {
    _id
    cardNumber
    createdAt
    amount
    isInScope
    description
    status
    glCode
    project {
      _id
      projectId
      property {
        _id
        name
      }
      type
    }
    client {
      _id
      glCodes {
        code
        name
      }
    }
    supplier {
      _id
      company {
        ...supplierCompanyFields
      }
    }
    property {
      _id
      name
    }
    invoices {
      ...invoiceFragment
    }
    createdBy {
      _id
    }
    attachments {
      url
      fileName
      fileSize
    }
  }

  ${invoiceFragment}
  ${supplierCompanyFields}
`;

export const checklistFragment = gql`
  fragment checklistFragment on MaintenancePlan {
    checklist
    materials
    notes
    searchFiles {
      hits {
        _id
        url
        fileName
        fileType
      }
    }
  }
`;

export const userFragment = gql`
  fragment userFragment on User {
    _id
    firstName
    lastName
    fullName
    status
    title
    email
    remarks
    addressTwo
    profile {
      cellPhoneNumber
      workPhoneNumber
      faxPhoneNumber
    }
    userGroups {
      userGroup {
        _id
        name
      }
    }
    address {
      formatted_address
      address_parts {
        street_number
        route
        locality
        administrative_area_level_2
        administrative_area_level_1
        country
        postal_code
        postal_code_suffix
        neighborhood
      }
      geo {
        coordinates
        type
      }
    }
    technicianSkills
    isSignedUp
    accessItems {
      permission
    }
  }
`;

export const defaultProjectFragment = gql`
  fragment defaultProjectFragment on Project {
    _id
    description
    type
    createdAt
    invoiceDescription
    endDate
    startDate
    status
    location
    priority {
      id
      name
    }
    parent {
      _id
    }
    serviceType {
      _id
      name
    }
    contact {
      _id
      fullName
      email
      profile {
        cellPhoneNumber
        workPhoneNumber
      }
    }
    client {
      _id
      name
    }
    property {
      _id
      name
      address {
        formatted_address
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          administrative_area_level_2
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
      }
    }
    technicians {
      _id
      fullName
      firstName
      lastName
      profile {
        cellPhoneNumber
      }
      emails {
        address
      }
      userGroups {
        userGroup {
          _id
          name
        }
      }
    }
    manager {
      _id
      fullName
      firstName
      lastName
      profile {
        cellPhoneNumber
      }
      emails {
        address
      }
    }
    suppliers {
      _id
      status
      type
      rating {
        average
        stars
      }
      createdAt
      updatedAt
      company {
        name
        emails {
          email
          type
        }
        phones {
          phone
          isPrimary
          legacyFormat
        }
      }
    }
  }
`;

export const defaultRecurringWOFragment = gql`
  fragment defaultRecurringWOFragment on RecurringProject {
    _id
    description
    createdAt
    invoiceDescription
    endDate
    startDate
    status
    location
    serviceType {
      _id
      name
    }
    client {
      _id
      name
    }
    property {
      _id
      name
      address {
        formatted_address
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          administrative_area_level_2
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
      }
    }
    manager {
      _id
      firstName
      lastName
      profile {
        cellPhoneNumber
      }
      emails {
        address
      }
    }
    suppliers {
      _id
      status
      type
      rating {
        average
        stars
      }
      createdAt
      updatedAt
      company {
        name
        emails {
          email
          type
        }
        phones {
          phone
          isPrimary
          legacyFormat
        }
      }
    }
    technicians {
      _id
      firstName
      lastName
      profile {
        cellPhoneNumber
      }
      emails {
        address
      }
      userGroups {
        userGroup {
          _id
          name
        }
      }
    }
  }
`;

export const clientConfigFragment = gql`
  fragment clientConfigFragment on ClientConfigs {
    useTechnicianSkills
    useTechnicianTeams
    projectPriorities {
      id
      name
      amount
      unit
      color
    }
    noManagerForRWONeeded
    supplierSource
    site {
      showUserCredentials
      specialPropertyFields
    }
  }
`;

export const contactUserFragment = gql`
  fragment contactUserFragment on ContactUser {
    _id
    firstName
    lastName
    fullName
    status
    title
    email
    remarks
    addressTwo
    isStaff
    profile {
      cellPhoneNumber
      workPhoneNumber
      faxPhoneNumber
    }
    address {
      formatted_address
      address_parts {
        street_number
        route
        locality
        administrative_area_level_2
        administrative_area_level_1
        country
        postal_code
        postal_code_suffix
        neighborhood
      }
      geo {
        coordinates
        type
      }
    }
  }
`;
