import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectType } from '@poly/constants';
import { CHILD_PROJECT_ELASTIC_QUERY } from '@poly/client-utils';
import { Loader, projectStatusUITransform } from '@poly/site-ui';
import { WorkOrdersTabTable } from '../../../components/property/tabs/WorkOrdersTabTable.js';
import { projectFormatter } from '../../../util/project-utils.js';
import {
  entityFormatter,
  fieldFormatter,
  formatDateWithDashes,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import { useSupplierProjectsQuery } from '../../../hooks/suppliers.js';

const mapActiveTabToWoQuery = {
  wos: {
    bool: {
      must: { match: { type: ProjectType.WORK_ORDER } },
      must_not: CHILD_PROJECT_ELASTIC_QUERY,
    },
  },
  recurring: CHILD_PROJECT_ELASTIC_QUERY,
};

const dateFormatter = (value) => fieldFormatter(formatDateWithDashes(value));

const columns = [
  ['WO #', R.prop('projectId'), projectFormatter],
  ['Description', R.prop('description'), fieldFormatter],
  ['End Date', R.prop('endDate'), dateFormatter],
  ['Status', R.identity, projectStatusUITransform],
];

export default function (props) {
  const supplierId = useSelector((state) => state.location.payload.id);

  const query = useSelector(
    (state) => mapActiveTabToWoQuery[state.suppliers.activeTab],
  );

  const { projects, loading } = useSupplierProjectsQuery({ supplierId, query });

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('Projects')();

  const projectsObj = entityFormatter(projects, columns);

  return <WorkOrdersTabTable {...props} {...projectsObj} />;
}
